<template>
  <el-container class="wrap">
    <el-main class="main-container">
      <el-header style="height: 35px; padding-left: 0; padding-right: 0">
        <el-row>
          <el-col :span="18">
            <el-form ref="form" :model="form" label-width="80px">
              <div
                class="tab-wrap"
                v-for="(item, index) in tabData"
                :key="index"
                @click="tap_click(index, item)"
              >
                <div
                  style="display: inline-block"
                  :class="{ active: index === chooseIndex }"
                >
                  {{ item.name }}
                  <div class="arrow">
                    <i
                      :class="{ currentActive: 'ASC' === item.isAsc }"
                      class="el-icon-caret-top"
                    ></i>
                    <i
                      :class="{ currentActive: 'DESC' === item.isAsc }"
                      class="el-icon-caret-bottom"
                    ></i>
                  </div>
                </div>
              </div>
              <!-- <el-col :span="6">
                  <el-form-item label="票务名称">
                    <el-input clearable v-model="form.ticketInfo"></el-input>
                  </el-form-item>
                </el-col> -->
            </el-form>
          </el-col>
          <el-col :span="6" style="position: relative">
            <el-input
              v-model="form.ticketInfo"
              placeholder="请输入票务名称"
            ></el-input>
            <img
              class="input-image"
              @click="searchEvent"
              src="../../../../assets/images/input_search.png"
              alt=""
              srcset=""
            />
            <!-- <el-button @click="searchEvent" class="clean-button">{{$t('search')}}</el-button>
            <el-button @click="cleanForm" class="clean-button">{{$t('clean')}}</el-button> -->
          </el-col>
        </el-row>
      </el-header>
      <el-main class="table-container">
        <el-table
          :data="tableData"
          :height="tableHeight"
          style="width: 100%"
          border
          :row-class-name="tableRowClassName"
          @selection-change="handleSelectionChange"
        >
          <el-table-column type="index" align="center" label="序号" width="55">
          </el-table-column>
          <el-table-column align="center" width="55">
            <template slot-scope="scope">
              <el-radio
                v-model="templateRadio"
                :label="scope.$index"
                @change.native="getTemplateRow(scope.$index, scope.row)"
                >.&nbsp;&nbsp;</el-radio
              >
            </template>
          </el-table-column>
          <el-table-column align="left" min-width="120" label="票务信息">
            <template slot-scope="scope">
              {{ scope.row.ticketInfo
              }}<i
                style="color: #d2514b; font-size: 12px; margin-left: 5px"
                v-if="scope.row.returnFlag === 'N'"
                >(不支持退票)</i
              >
            </template>
          </el-table-column>
          <el-table-column
            prop="sportsProjectName"
            align="center"
            width="110"
            label="运动项目"
          >
          </el-table-column>
          <el-table-column
            prop="startTime"
            align="center"
            width="110"
            label="开始时间"
          >
          </el-table-column>
          <el-table-column
            prop="endTime"
            align="center"
            width="110"
            label="结束时间"
          >
          </el-table-column>
          <el-table-column
            prop="deadline"
            align="center"
            width="180"
            label="截止时间"
          >
          </el-table-column>
          <el-table-column
            prop="price"
            align="center"
            width="80"
            label="价格(元)"
          >
          </el-table-column>
        </el-table>
        <div class="page-container-ticket">
          <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="form.pageNum"
            :page-sizes="[10, 20, 30, 40]"
            :page-size="form.pageSize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="form.total"
          >
          </el-pagination>
        </div>
      </el-main>
    </el-main>
    <el-aside class="order-container" width="300px" style="margin-right: 15px">
      <el-form ref="form1" :model="form1" :rules="rules">
        <div class="note-container">
          <p class="note-text">消费清单</p>
          <!-- <el-input maxlength="11" clearable v-model="form1.customerPhone" class="phone-input radius-input" placeholder="请输入会员手机号" @keyup.enter.native="getCardList"></el-input> -->
          <el-form-item prop="customerPhone" style="margin-left: 0">
            <!-- <el-input maxlength="11" clearable v-model="form1.customerPhone" class="phone-input radius-input" placeholder="请输入会员名或手机号" @keyup.enter.native="getCardList"></el-input> -->
            <el-input
              maxlength="11"
              clearable
              v-model="form1.customerPhone"
              class="phone-input radius-input"
              placeholder="请输入会员手机号，并按回车"
              @keyup.enter.native="getCardList"
              @input="getPhone"
            ></el-input>
          </el-form-item>
          <!-- 订单详情 -->
          <div class="ticket-wrap" v-if="templateSelection">
            <div class="venue-price">
              <p class="ticket-time">{{ templateSelection.ticketInfo }}</p>
              <p class="price">￥{{ templateSelection.price }}元</p>
            </div>
            <div class="order-detail-wrap">
              <p class="day-text">
                <span style="margin-right: 10px">数量</span
                ><el-input-number
                  clearable
                  style="width: 100px"
                  size="mini"
                  v-model="form1.qty"
                  @change="handleChange"
                  :min="1"
                ></el-input-number>
              </p>
              <div class="venue-order-wrap">
                <div class="name-text">
                  <p>消费时段</p>
                </div>
                <div class="time-wrap">
                  <p class="order-time-text">
                    {{ templateSelection.startTime }}~{{
                      templateSelection.endTime
                    }}
                  </p>
                </div>
              </div>
              <div class="venue-order-wrap">
                <div class="name-text">
                  <p>截止日期</p>
                </div>
                <div class="time-wrap">
                  <p class="order-time-text">
                    {{ templateSelection.deadline }}
                  </p>
                </div>
                <div class="del-wrap" @click="del">
                  <i class="el-icon-delete"></i>
                </div>
              </div>
            </div>
          </div>
          <div
            class="ticket-wrap"
            style="text-align: center; font-size: 12px"
            v-else
          >
            暂无信息
          </div>
          <!-- 总计 -->
          <div class="price-wrap">
            <div class="price-text">
              <label for="" class="price-name">小计金额</label>
              <!-- <div class="price-modify"  v-if="buttonAuth.includes('venueOperation:order:tickeModifyPrice')" @click="disabled = !disabled">修改</div> -->
            </div>
            <!-- <el-form-item label="小计金额"> -->
            <el-input
              clearable
              v-if="!disabled"
              size="mini"
              class="radius-input"
              v-model="form1.totalPrice"
              step="0.01"
              min="0"
              type="number"
              :disabled="disabled"
            >
              <div class="input-slot" slot="suffix">元</div>
              <div class="input-slot" slot="prefix">¥</div>
            </el-input>
            <div class="price-disabled" v-if="disabled">
              ¥{{ form1.totalPrice || 0 }}元
            </div>
            <!-- </el-form-item> -->
            <!-- <el-button class="change-input" type="primary" round size="mini" v-if="changePay" @click="disabled = false">修改</el-button> -->
            <!-- <el-button class="change-input" type="primary" round size="mini" v-if="buttonAuth.includes('venueOperation:order:tickeModifyPrice')" @click="disabled = false">修改</el-button> -->
          </div>
          <!--TODO:-->
          <el-form-item label="支付方式:" class="payTypeItem">
            <el-radio-group v-model="payTypeRadio" class="radioGroup">
              <!-- <el-radio
                v-for="item in payBox"
                :key="item.value"
                :label="item.value"
                >{{ item.label }}</el-radio
              > -->
              <el-radio label="2">线下-现金</el-radio>
              <el-radio label="5">线下-微信</el-radio>
              <el-radio label="6" v-if="cardList.length > 0 && form1.isCanOfflineMemberCardPay">线下-会员卡</el-radio>
            </el-radio-group>
          </el-form-item>
          <!--TODO:-->
          <div class="member-card" v-if="payTypeRadio === '6'">
            <el-checkbox
              v-model="item.checked"
              v-for="(item, index) in cardList"
              :key="item.cardId"
              @change="onCheckMemberCard(index)"
              >{{
                `${item.cardName} （余额${item.remainAmount}元）`
              }}
            </el-checkbox>
          </div>
          <!-- 备注 -->
          <div class="remark-wrap">
            <el-input
              clearable
              type="textarea"
              maxlength="100"
              :rows="6"
              placeholder="请输入备注"
              v-model="form1.businessRemark"
            >
            </el-input>
            <p v-if="templateSelection">
              待支付
              <span
                >¥{{
                  finalPrice ? finalPrice : templateSelection.price
                }}元</span
              >
            </p>
          </div>
          <!-- 按钮 -->
          <div class="button-wrap">
            <div
              class="default-button btn-save"
              v-if="buttonAuth.includes('venueOperation:order:tickeOrderSave')"
              @click="settlementOrder(1)"
            >
              保存
            </div>
            <div
              class="default-button"
              v-if="buttonAuth.includes('venueOperation:order:tickeOrderSave')"
              @click="settlementOrder(2)"
            >
              结算
            </div>
          </div>
        </div>
      </el-form>
    </el-aside>
    <!-- 结算弹窗 -->
    <add-dialog
      ref="addDialog"
      :Language="Language"
      typeText="票务"
      :show="addDialog.visible"
      :isTicket="addDialog.isTicket"
      @closeDialog="changeAddDialog"
      @refesh="getData"
    ></add-dialog>
  </el-container>
</template>

<script>
import { mapState } from "vuex";
import mixin from "@/mixins/index";
import apis from "@/apis";
import addDialog from "./addDialog.vue";
import TicketClass from "./ticketClass";
import { debounce } from "lodash";
/* eslint-disable */
export default {
  mixins: [mixin],
  data() {
    let validatePhone = (rule, value, callback) => {
      console.log("检验手机号码", value);
      if (value) {
        var phone = value.replace(/\s/g, ""); //去除空格
      } else {
        console.log("没有手机号");
      }
      //校验手机号，号段主要有(不包括上网卡)：130~139、150~153，155~159，180~189、170~171、176~178。14号段为上网卡专属号段
      let regs = /^((13[0-9])|(17[0-1,3,6-8])|(15[^4,\\D])|(18[0-9]))\d{8}$/;
      let reg = /^\d{3}-\d{8}$|^\d{4}-\d{7,8}$/;
      if (!value) {
        callback([new Error("请输入会员手机号")]);
      } else {
        if (!regs.test(phone) && !reg.test(phone)) {
          callback([new Error("会员手机号输入不合法")]);
        } else {
          callback();
        }
      }
    };
    return {
      tabData: [
        {
          name: "开始时间",
          type: "startTime",
          isAsc: "ASC",
        },
        {
          name: "结束时间",
          type: "endTime",
          isAsc: "ASC",
        },
        {
          name: "截止时间",
          type: "deadline",
          isAsc: "ASC",
        },
        {
          name: "售价",
          type: "price",
          isAsc: "ASC",
        },
      ],
      chooseIndex: 999,
      tableData: [],
      tableHeaderData: [],
      orderData: [],
      textarea: "",
      form: new TicketClass("form"),
      addDialog: {
        visible: false,
        isTicket: true,
      },
      // num: 1,
      templateRadio: 0,
      templateSelection: null,
      sportsProjectCode: "", //运动项目
      rules: {
        customerPhone: [
          {
            required: true,
            message: "请输入手机号",
            trigger: ["blur", "change"],
            validator: validatePhone,
          },
        ],
      },
      form1: {
        customerPhone: "",
        businessRemark: "",
        totalPrice: "",
        ticketSetId: "",
        qty: 1,
        isCanOfflineMemberCardPay: true,
      },
      totalPrice: 0,
      disabled: true,
      cardList: [],
      discount: 1,
      payTypeRadio: "",
      payBox: [],
    };
  },
  computed: {
    ...mapState(["Language", "changePay", "DROPDOWNBOX"]),
    finalPrice() {
      return (this.form1.totalPrice * this.discount * 1).toFixed(2);
    },
  },
  components: {
    addDialog,
  },
  methods: {
    getData() {
      this.searchData();
      this.getCardList()
    },
    changeAddDialog() {
      this.addDialog.visible = false;
      this.searchData();
    },
    /**
     * @function 清空搜索栏
     */
    cleanForm() {
      this.form = new TicketClass("form");
      this.$nextTick(() => {
        this.searchEvent();
      });
    },
    handleChange(value) {
      this.form1.totalPrice = (Number(this.templateSelection.price) * value).toFixed(2);
      this.form1.qty = value;
    },
    getTemplateRow(index, row) {
      console.log("++++++++++index:", index, "+++++row:", row);
      this.form1.totalPrice = "";
      this.form1.ticketSetId = row.id;
      this.templateSelection = row;
      this.form1.isCanOfflineMemberCardPay = row.isCanOfflineMemberCardPay
      this.form1.totalPrice = this.templateSelection.price * this.form1.qty;
      this.sportsProjectCode = (row && row.sportsProjectCode) || "";
      console.log(row.payType, "payType");
      const arr = row.payType.split(",");
      this.payBox = [];
      this.DROPDOWNBOX.VENUE_PAY_TYPE.forEach((item) => {
        if (arr.indexOf(item.value) >= 0) {
          this.payBox.push(item);
        }
      });
      // this.getCardList()
    },
    del() {
      this.templateSelection = null;
      this.templateRadio = false;
      this.form1.totalPrice = "";
    },
    tap_click(index, item) {
      this.chooseIndex = index;
      if (item.isAsc === "ASC") {
        item.isAsc = "DESC";
      } else {
        item.isAsc = "ASC";
      }
      this.form.orderByColumns = item.type;
      this.form.isAscs = item.isAsc;
      this.searchData();
    },
    //监听手机号长度
    getPhone(value){
      console.log(value);
      if(value == "" || value.length < 11){
        this.cardList = [];
        this.payTypeRadio = '';
      }
    },
    settlementOrder: debounce(function (type) {
      const commitData = {
        ...this.form1,
        ...this.templateSelection,
      };
      if (!this.templateSelection) {
        this.$message.error("请选择票务");
        return;
      }
      var re = /^1\d{10}$/;
      if (!re.test(commitData.customerPhone)) {
        this.$message.error("请输入手机号");
        return;
      }
      console.log("+++++++++type:", type);
      // 通过卡种判断
      // let payType = ""
      let memberCardId = "";
      const index = this.cardList.findIndex((item) => item.checked);
      if (index > -1) {
        // payType = "3" // 1-微信； 2-线下； 3-储值卡
        memberCardId = this.cardList[index].cardId;
      }
      // else {
      //   payType = "1"吗
      // }
      commitData.memberCardId = memberCardId;
      commitData.payType = this.payTypeRadio;
      this.$refs.addDialog.getDataQuery(commitData, type).then(() => {
        if (type === 2) {
          this.addDialog.visible = true;
        }
      });
    }, 500),
    searchData() {
      this.tableData = [];
      this.sportsProjectCode = "";
      this.$http.post(apis.tickeOrder_list, this.form).then((res) => {
        if (res.data.code === 0) {
          this.tableData = res.data.rows;
          // this.tableData.forEach((item) => {
          //   this.$set(item, 'checked', false)
          // })
          this.form.total = res.data.total;
          console.log("+++++++++票务：", this.tableData);
          if (this.tableData.length > 0) {
            this.templateRadio = 0;
            // this.payTypeRadio = '2'
            this.getTemplateRow(0, this.tableData[0]);
          }
        }
      });
    },
    getCardList() {
      console.log("++++票务+++++getCardList");
      const params = {
        tel: this.form1.customerPhone,
        venueId: this.venueId,
        // sportProjectCode: this.sportsProjectCode,
      };
      this.cardList = [];
      // 校验电话号码非空
      if (!params.tel) return;
      // 判断该票务是否支持会员卡支付
      if (!this.form1.isCanOfflineMemberCardPay) return
      // 校验票务选项
      // if (this.tableData.length > 0) {
      //   params.sportProjectCode =
      //     (this.tableData[this.templateRadio] &&
      //       this.tableData[this.templateRadio].sportsProjectCode) ||
      //     "";
      // } else {
      //   return;
      // }
      this.$http
        .get(apis.getCardListByTel, {
          params,
        })
        .then((res) => {
          if (res.data.code === 0) {
            const data = res.data.data;
            if (data.length > 0) {
              const list = data.map((item) => ({
                cardId: item.cardId,
                cardName: item.cardName,
                remainAmount: item.remainAmount,
                consumeDiscount: item.consumeDiscount,
                checked: false,
              }));
              this.cardList = list;
            }
          }
        });
    },
    onCheckMemberCard(num) {
      this.cardList.map((item, index) => {
        if (index !== num) {
          item.checked = false;
        }
        return item;
      });
      //重算
      this.calcDiscount();
    },
    calcDiscount() {
      const index = this.cardList.findIndex((item) => item.checked);
      if (index > -1) {
        this.discount = this.cardList[index].consumeDiscount * 1;
      } else {
        this.discount = 1;
      }
    },
  },
};
</script>

<style lang="less" scoped>
@import "../../../../assets/styles/mixin.scoped.less";
.input-image {
  width: 16px;
  height: 16px;
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  cursor: pointer;
}
.page-container-ticket {
  display: grid;
  justify-content: right;
  margin-top: 10px;
}
.clean-button {
  background: #439bff;
  width: 90px;
  height: 30px;
  line-height: 4px;
  color: #fff;
}

.arrow {
  display: inline-block;
  vertical-align: middle;
  margin-top: -3px;
  i {
    display: block;
  }
}

.currentActive {
  color: #d2514b;
}

.tabs-container {
  margin: 0 20px;
}

.tab-wrap {
  display: inline-block;
  /* width: 90px; */
  border: 1px solid rgba(228, 233, 241, 1);
  font-size: 12px;
  line-height: 30px;
  color: #606266;
  text-align: center;
  padding: 5px 10px;
  background: #fff;
  cursor: pointer;
  margin-right: 10px;
  width: 16%;
}

.wrap {
  height: calc(100vh - 200px);
  // background: gray;
}

.main-container {
  padding-top: 0;
  padding-left: 0;
  .header-container {
    width: 100%;
    height: 143px;
    border-radius: 4px;
    border: 1px solid rgba(228, 233, 241, 1);
    padding-top: 0;
    padding-left: 0;
    .day-container {
      width: 69px;
      box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);
      border-radius: 4px;
      margin-top: 30px;
      overflow: hidden;
      float: left;
      margin-right: 20px;
      cursor: pointer;
    }
  }
  .table-container {
    width: 100%;
    margin-left: 0;
    margin-right: 0;
    margin: 0;
    padding-left: 0;
    padding-right: 0;
  }
}

.order-container {
  background: #fff;
  border: 1px solid rgba(228, 233, 241, 1);
  border-radius: 4px;
  // background: blue;
  .note-container {
    // width: 300px;
    max-height: calc(100vh - 270px);
    // margin-top: 20px;
    // border-radius: 4px;
    // border: 1px solid rgba(228, 233, 241, 1);
    // overflow: hidden;
    // padding-bottom: 200px;
    position: relative;
    .note-text {
      font-size: 15px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: rgba(36, 45, 65, 1);
      text-align: center;
      margin-top: 20px;
    }
    .phone-input {
      width: calc(100% - 30px);
      margin: 0 15px;
      margin-top: 20px;
      border-radius: 16px;
    }
    .ticket-wrap {
      width: 100%;
      border-bottom: 1px solid #e4e9f1;
      border-top: 1px solid #e4e9f1;
      overflow: hidden;
      padding: 10px 0;
      margin-top: 20px;
      .venue-price {
        font-size: 12px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        text-indent: 8px;
        line-height: 40px;
        height: 40px;
        .ticket-time {
          display: inline-block;
          color: rgba(36, 45, 65, 1);
        }
        .price {
          float: right;
          display: inline-block;
          margin-right: 20px;
          color: #d2514b;
        }
      }
      .order-detail-wrap {
        float: left;
        width: 100%;
        .day-text {
          display: flex;
          justify-content: space-between;
          align-items: center;
          font-size: 12px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: rgba(36, 45, 65, 1);
          height: 40px;
          /* text-indent: 15px; */
          line-height: 40px;
          margin-left: 18px;
        }
        .venue-order-wrap {
          overflow: hidden;
          margin-top: 5px;
          .name-text {
            float: left;
            width: 80px;
            font-size: 12px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: rgba(148, 152, 158, 1);
            text-indent: 15px;
            line-height: 30px;
          }
          .time-wrap {
            float: left;
            width: calc(100% - 120px);
            .order-time-text {
              font-size: 12px;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              color: rgba(148, 152, 158, 1);
              text-indent: 15px;
              line-height: 30px;
            }
          }
          .del-wrap {
            width: 20px;
            float: left;
            cursor: pointer;
            .el-icon-delete {
              line-height: 30px;
              color: #ae433e;
            }
          }
        }
      }
    }
    .price-wrap {
      // width: 100%;
      display: flex;
      padding: 18px 0px 18px 15px;
      border-bottom: 1px solid #e4e9f1;
      justify-content: space-between;
      align-content: center;
      .price-text {
        display: flex;
        flex-direction: row;
        line-height: 28px;
      }
      .price-name {
        width: 74px;
        line-height: 28px;
        font-size: 14px;
        color: rgba(36, 45, 65, 1);
      }
      .price-modify {
        width: 40px;
        padding-top: 10px;
      }
      .price-disabled {
        // width: calc(100% - 138px);
        margin-right: 20px;
        font-size: 12px;
        color: rgba(36, 45, 65, 1);
      }
      .radius-input {
        width: calc(100% - 138px);
        margin-right: 20px;
        .input-slot {
          line-height: 28px;
          height: 28px;
          font-size: 12px;
          color: #242d41;
        }
      }
    }
    .payTypeItem {
      padding: 10px 0 0 10px;
      margin-bottom: 0;
      .el-form-item__label {
        line-height: 0 !important;
      }
    }
    .radioGroup {
      display: flex;
      flex-direction: column;
      padding: 0 16px;
      label {
        padding: 3px 0;
      }
    }
    .member-card {
      padding: 8px 10px;
    }
    .remark-wrap {
      // border-bottom: 1px solid #E4E9F1;
      padding: 20px 10px;
      p {
        text-align: right;
        font-size: 12px;
        line-height: 30px;
        height: 30px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: rgba(36, 45, 65, 1);
        span {
          color: #d2514b;
        }
      }
    }
    .button-wrap {
      width: 100%;
      margin-top: 20px;
      display: grid;
      grid-template-columns: 50% 50%;
      align-items: center;
      justify-items: center;
      justify-content: center;
      align-content: center;
      .default-button {
        width: 122px;
        height: 32px;
        background: #eaecf1;
        border-radius: 4px;
        line-height: 32px;
        height: 32px;
        border: 0;
        text-align: center;
        margin: 0 auto;
        color: #439bff;
        cursor: pointer;
        &:hover {
          color: #ffffff;
          background: #439bff;
        }
      }
      .btn-save {
        background: #439bff;
        color: #fff;
      }
    }
  }
}
.price-wrap {
  padding: 10px 10px;
  padding-top: 25px;
  border-bottom: 1px solid #e4e9f1;
  overflow: hidden;
  .el-form-item {
    float: left;
    width: 78%;
  }
  .change-input {
    float: left;
    width: 19%;
    margin-left: 8px;
  }
}
</style>
<style lang="less">
@import "../../../../assets/styles/mixin.less";
.radius-input input {
  border-radius: 50px;
}
</style>
