<!--
 * @Author: zhongtao
 * @LastEditors: zhongtao
-->
<template>
    <div class="wrap">
        <div class="tabs-container">
            <el-tabs class="taps-card" v-model="form.ZT">
                <el-tab-pane label="预约" name="1">
                     <order-com ref="order"></order-com>
                </el-tab-pane>
                <el-tab-pane label="票务" name="2">
                      <ticket ref="ticket" ></ticket>
                </el-tab-pane>
            </el-tabs>
            <!-- <div class="tap-content">
                <order-com ref="order" v-show="form.ZT==='1'"></order-com>
                <ticket ref="ticket" v-show="form.ZT==='2'"></ticket>
            </div> -->
        </div>
    </div>
</template>

<script>
import OrderSearchClass from './orderSearchClass'
import orderCom from './compoments/order.vue'
import Ticket from './compoments/ticket.vue'

export default {
  components: {
      orderCom,
      Ticket,
  },
  data() {
      return {
          form: new OrderSearchClass('form'),
      }
  },
  activated() {
    if (this.$route.query.type) {
      this.form.ZT = this.$route.query.type
      this.$refs[this.$route.query.type === '1' ? 'order' : 'ticket'][this.$route.query.type === '1' ? 'form' : 'form1'].customerPhone = this.$route.query.user
    }
    // 从会员管理跳转过来
    if (this.$route.params.type && this.$route.params.customerPhone) {
        this.form.ZT = this.$route.params.type
        // this.$refs[this.$route.params.type === '1' ? 'order' : 'ticket'][this.$route.params.type === '1' ? 'form' : 'form1'].customerPhone = this.$route.params.customerPhone
        this.$refs['order']['form'].customerPhone = this.$route.params.customerPhone
        this.$refs['ticket']['form1'].customerPhone = this.$route.params.customerPhone
    }
  },
    deactivated() {
        this.$refs['order']['form'].customerPhone = ""
        this.$refs['ticket']['form1'].customerPhone = ""
    },
}
</script>

<style lang="less" scoped>
@import "../../../assets/styles/mixin.scoped.less";
.wrap{
    width: 100%;
    height: 100%;
    .tabs-container{
        margin-top: 20px;
        margin-left: 20px;
        .taps-card {
            width: 100%;
            height: 100%;
        }
    }
}
</style>
<style lang="less">
@import "../../../assets/styles/mixin.less";
</style>
