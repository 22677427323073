/*
 * @Author: zhongtao
 * @LastEditors: zhongtao
 */
// import zh from '@/locales/zh.json'
// import en from '@/locales/en.json'

// const Lang = {
//   zh,
//   en,
// }
/* eslint-disable */
class searchFrom {
  validatePhone = (rule, value, callback) => {
    console.log("检验手机号码", value)
    if (value) {
      var phone=value.replace(/\s/g, "");//去除空格
    } else {
      console.log("没有手机号")
    }
    //校验手机号，号段主要有(不包括上网卡)：130~139、150~153，155~159，180~189、170~171、176~178。14号段为上网卡专属号段
    let regs = /^((13[0-9])|(17[0-1,3,6-8])|(15[^4,\\D])|(18[0-9]))\d{8}$/;
    let reg = /^\d{3}-\d{8}$|^\d{4}-\d{7,8}$/;
    if(!value){
        callback([new Error('请输入会员手机号')]);
    } else {
        if(!regs.test(phone)&&!reg.test(phone)){
            callback([new Error('会员手机号输入不合法')]);
        } else {
            callback();
        }
   }
  }
  constructor(type, lanuage = 0) {
    if (type === 'form') {
      this.orderNo = ''
      this.customerPhone = ''
      this.reservateOrderStatus = '1'
      this.totalPrice = 0
      this.businessRemark = ''
    } else if (type === 'rule') {
      this.customerPhone = [
        {
          required: true,
          // message: Lang[lanuage === 1 ? `en` : `zh`].ple_ent_phoneNumber,
          message: '请输入会员手机号',
          trigger: ['blur','change'],
        },
        {
          min: 11,
          max: 11,
          message: '长度在11个字符',
          trigger: 'blur',
        },
      ],
      this.reservateOrderStatus = [
        {
          required: true,
          message: '请选择预定类型',
          trigger: 'change',
        },
      ]
    }
  }
}
export default searchFrom
